import React, {useCallback, useEffect, useState} from 'react';
import './Modal.css';
import {useStateRef} from "../utils";

let ref,
    dummyRef;

export const TextInputModal = ({startText, close, confirm, label, extraButton}) => {
    const [areaHeight, setAreaHeight] = useState();
    const [text, setText, textRef] = useStateRef(startText || '');

    const submit = useCallback(() => {
        let confirmText = textRef.current;
        if (confirmText.length === 0) {
            close();
            return;
        }
        setText('');
        ref && ref.focus();
        confirm(confirmText);
    }, [textRef, setText, confirm, close])

    useEffect(() => {
            const onkeypress = e => e.keyCode === 13 && submit();
            window.removeEventListener('keypress', onkeypress)
            window.addEventListener('keypress', onkeypress)
            return () => window.removeEventListener('keypress', onkeypress)
        },
        [submit]
    )

    useEffect(() => {
        const newHeight = Math.max(dummyRef.clientHeight, 30);

        newHeight !== areaHeight && setAreaHeight(newHeight);
    }, [text, areaHeight])

    return (
        <div className="modalWrapper">
            <div className="modalOverlay"
                 onClick={close}
            />
            <div
                className="modal"
                onClick={e => e.target.tagName === 'DIV' && ref && ref.focus()}
            >
                {label && <h3>{label}</h3>}
                <textarea
                    className="modalTextInput"
                    value={text}
                    onChange={e => setText(e.target.value.replace('\n', ''))}
                    autoFocus
                    ref={node => ref = node}
                    onFocus={(e) => {
                        if (e && e.target && e.target.selectionEnd !== undefined)
                            e.target.selectionStart = text.length
                    }}
                    style={{
                        height: areaHeight,
                        maxHeight: areaHeight
                    }}
                />
                <div
                    className="dummy"
                    ref={node => dummyRef = node}
                    style={{width: ref && ref.clientWidth}}
                >
                    {text}
                </div>
                <button
                    className={'yes'}
                    onClick={submit}
                >
                    Confirm
                </button>
                {extraButton}
            </div>
        </div>
    );
}