import {useEffect, useRef, useState} from "react";

export const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & (0x3 | 0x8));
        return v.toString(16);
    });
}

export const dummyList = () => [{
    id: uuidv4(),
    label: 'My first list',
    items: [
        {
            id: uuidv4(),
            text: 'Find the best list app ever',
            checked: true
        },
        {
            id: uuidv4(),
            text: 'Manage lists by tapping the settings icon (upper left)',
            checked: false
        },
        {
            id: uuidv4(),
            text: 'Manage list items by tapping the edit list icon (upper right)',
            checked: false
        },
        {
            id: uuidv4(),
            text: `Use the four arrows icon thingy to sort a list.`,
            checked: false
        },
        {
            id: uuidv4(),
            text: `Find another list by scrolling to the right`,
            checked: false
        }
    ]
},
    {
        id: uuidv4(),
        label: 'Another list',
        items: [
            {
                id: uuidv4(),
                text: 'In the settings or when editing a list, you can change the label of a list or item by tapping the text.',
                checked: false
            },
        ]
    }]

export function useStateRef(initialValue) {
    const [value, setValue] = useState(initialValue);
    const ref = useRef(value);
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return [value, setValue, ref];
}