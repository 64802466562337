import React from 'react';
import {EditableListItem} from "./EditableListItem";
import {AddItemButton} from "../AddItemButton";
import {SortableList} from "../../general/SortableList/SortableList";
import {ListHeader} from "../ListHeader";

export const EditableList = ({list, updateList, openSettings, close}) => {
    const thingy = content => (
        <div className="editableListContainer">
            <ListHeader
                openSettings={openSettings}
                list={list}
                edit={close}
                editing={true}
            />
            {content}
        </div>
    )


    if (list.items?.length === 0)
        return thingy(
            <AddItemButton
                list={list}
                updateList={updateList}
                fullScreen
            />
        )


    const setText = (text, itemIndex) => {
        let newList = {...list};
        let items = newList.items = [...newList.items];
        let item = items[itemIndex] = {...items[itemIndex]};
        item.text = text;
        updateList(newList);
    }

    const deleteItem = index => {
        let newList = {...list};
        let items = newList.items = [...newList.items];
        items.splice(index, 1);
        updateList(newList);
    }

    const renderItem = (item, i, draggingClass) => (
        <EditableListItem
            item={item}
            text={item.text}
            setText={text => setText(text, i)}
            deleteItem={() => deleteItem(i)}
            draggingClass={draggingClass}
        />)

    return thingy(
        <SortableList
            items={list.items}
            setItems={items => {
                updateList({
                    ...list,
                    items
                })
            }}
            draggableClass={'draggableListItem deletableListItem'}
            renderItem={renderItem}
            addButton={(
                <AddItemButton
                    list={list}
                    updateList={updateList}
                />
            )}
        />
    )
}