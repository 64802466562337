import React from 'react';

export const DeleteModal = ({text, confirm, cancel}) => {
    return (
        <div className="modalWrapper">
            <div className="modalOverlay"
                 onClick={cancel}
            />
            <div className="modal deleteModal">
                <p>{text}</p>
                <div className="buttons">
                    <button
                        className={'no'}
                        onClick={confirm}
                    >
                        Confirm
                    </button>
                    <button
                        className={'yes'}
                        onClick={cancel}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
}