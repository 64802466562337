import React, {useState} from 'react';
import './Settings.css';
import {AddItemButton} from "../List/AddItemButton";
import {CloseIcon} from "../general/CloseIcon";
import {uuidv4} from "../utils";
import {DeleteModal} from "../general/DeleteModal";
import {SortableList} from "../general/SortableList/SortableList";
import {TextInputModal} from "../general/TextInputModal";
import {CheckBox} from "../general/CheckBox";

export const Settings = ({close, lists, saveLists, colorMode, setColorMode, sortLists, setSortLists}) => {
    const [deleting, setDeleting] = useState(false);
    const [copyingLists, setCopyingLists] = useState(false);

    const deleteList = i => {
        let newLists = [...lists];
        newLists.splice(i, 1);
        saveLists(newLists)
    }

    const addList = label => {
        const newList = {
            id: uuidv4(),
            label,
            items: []
        }
        saveLists([...lists, newList])
    }

    const updateListLabel = (text, index) => {
        let newLists = [...lists];
        let newList = newLists[index] = lists[index];
        newList.label = text;
        saveLists(newLists)
    }

    const renderItem = (list, i) => (
        <>
            <div
                className="deleteButton"
                onClick={() => setDeleting(i)}
            >
                <CloseIcon/>
            </div>
            <AddItemButton
                label={'Set list name'}
                addList={text => updateListLabel(text, i)}
                startText={list.label}
                closeOnAdd
            />
        </>
    )

    const renderLists = () => (
        <SortableList
            items={lists}
            setItems={saveLists}
            renderItem={renderItem}
            draggableClass={'draggableList'}
            addButton={(
                <AddItemButton
                    label={'New list'}
                    addList={addList}
                    closeOnAdd
                />
            )}
        />)

    const renderDeleteModal = () => deleting !== false && (
        <DeleteModal
            text={`Delete list "${lists[deleting].label}"?`}
            cancel={() => setDeleting(false)}
            confirm={() => {
                setDeleting(false)
                deleteList(deleting)
            }}
        />
    )
    const renderCopyListsModal = () => copyingLists && (
        <TextInputModal
            close={() => setCopyingLists(false)}
            label={'Copy or paste list json'}
            extraButton={(
                <button
                    className={'yes'}
                    onClick={() => {
                        const el = document.createElement('textarea');
                        el.value = JSON.stringify(lists);
                        document.body.appendChild(el);
                        el.select();
                        document.execCommand('copy');
                        document.body.removeChild(el);
                    }}
                >
                    Copy to clipboard
                </button>
            )}
            confirm={text => {
                try {
                    const newLists = JSON.parse(text);
                    saveLists(newLists);
                    alert('Success.')
                    setCopyingLists(false);
                } catch (e) {
                    alert('Not success.')
                    alert('The opposite of success.')
                    console.log('e: ', e);
                }
            }}
        />
    )

    const renderColorButton = key => {
        const active = key === colorMode;
        return (
            <button
                className={`colorButton ${key}${active ? ' active' : ''}`}
                onClick={() => active || setColorMode(key)}
            >
                {key}
            </button>
        )
    }

    return (
        <div className="settings">
            {renderDeleteModal()}
            {renderCopyListsModal()}
            <h1 className="settingsHeader">Colours and Lists</h1>
            <div
                className="copyListsButton"
                onClick={() => setCopyingLists(true)}
            />
            <div className="colorSettings">
                {renderColorButton('dark')}
                {renderColorButton('light')}
                {renderColorButton('pink')}
            </div>
            <div className="sortCheckWrapper">
                <div
                    className={'sortCheck'}
                    onClick={() => setSortLists(!sortLists)}
                >
                    <CheckBox checked={sortLists}/>
                    Move checked down
                </div>
            </div>
            <button
                className="closeSettingsButton noStyle"
                onClick={close}
            >
                <CloseIcon/>
            </button>
            {renderLists()}
        </div>
    );
}