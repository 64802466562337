import React, {useState} from 'react';
import {TextInputModal} from "../general/TextInputModal";
import {uuidv4} from "../utils";

export const AddItemButton = ({list, updateList, fullScreen, addList, startText, label, closeOnAdd}) => {
    const [adding, setAdding] = useState(false)

    const addItem = text => {
        if (addList) {
            addList(text)
            closeOnAdd && setAdding(false);
            return;
        }
        updateList({
            ...list,
            items: [
                ...list.items,
                {
                    id: uuidv4(),
                    text,
                    checked: false
                }
            ]
        })
        closeOnAdd && setAdding(false);
    }

    const modal = adding && (
        <TextInputModal
            startText={startText || ''}
            close={() => setAdding(false)}
            confirm={addItem}
            label={label || 'New item'}
        />
    );

    const button = startText ? (
        <div
            onClick={() => setAdding(true)}
        >
            {startText}
        </div>
    ) : (
        <div
            className={`addItemButton${fullScreen ? ' fullScreen' : ''}`}
            onClick={() => setAdding(true)}
        >
            <svg xmlns="http://www.w3.org/2000/svg" height="100" viewBox="0 0 24 24" width="100">
                <path
                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"
                    fill="white"
                />
            </svg>
        </div>
    );
    return (
        <>
            {modal}
            {fullScreen ? (
                <div className="addItemButtonFullscreenWrapper">
                    {button}
                </div>
            ) : button}
        </>
    );
}