import React, {useState} from 'react';
import {TextInputModal} from "../../general/TextInputModal";
import {DeleteModal} from "../../general/DeleteModal";
import {CloseIcon} from "../../general/CloseIcon";
import {CheckBox} from "../../general/CheckBox";

export const EditableListItem = ({item, setText, deleteItem, draggingClass}) => {
    const [editing, setEditing] = useState(false);
    const [deleting, setDeleting] = useState(false);

    return (
        <>
            {editing && (
                <TextInputModal
                    label={'Set item text'}
                    startText={item.text}
                    close={() => setEditing(false)}
                    confirm={text => {
                        setText(text)
                        setEditing(false)
                    }}
                />
            )}
            {deleting && (
                <DeleteModal
                    text={`Delete "${item.text}"?`}
                    cancel={() => setDeleting(false)}
                    confirm={() => {
                        deleteItem()
                    }}
                />
            )}
            <CheckBox checked={item.checked}/>
            <div
                className={`editableListItem listItem${
                    draggingClass
                }`}
                onClick={() => setEditing(true)}
            >
                {item.text}
            </div>
            <div
                className="deleteButton"
                onClick={() => setDeleting(true)}
            >
                <CloseIcon/>
            </div>
        </>
    );
}