import React from 'react';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {uuidv4} from "../../utils";

export const SortableList = ({items, setItems, renderItem, addButton, draggableClass}) => (
    <DragDropContext onDragEnd={result => {
        const {destination, source} = result;
        if (!destination) return;
        if (destination.index === source.index) return;
        let newList = [...items];

        newList.splice(source.index, 1);
        newList.splice(destination.index, 0, items[source.index])
        setItems(newList)
        setItems(newList)
    }}>
        <div className="sortList">
            <Droppable droppableId={uuidv4()}>
                {(provided) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className="items"
                    >
                        {items.map((item, i) => (
                                <Draggable
                                    key={item.id}
                                    draggableId={item.id}
                                    index={i}
                                >
                                    {
                                        (provided, snapshot) => {
                                            const draggingClass = snapshot.isDragging ? ' dragging' : '';
                                            return (
                                                <div
                                                    className={`${draggableClass}${draggingClass}`}
                                                    {...provided.draggableProps}
                                                    ref={provided.innerRef}
                                                    {...provided.dragHandleProps}
                                                >
                                                    {renderItem(item, i, draggingClass)}
                                                </div>
                                            )
                                        }
                                    }
                                </Draggable>
                            )
                        )}
                        {provided.placeholder}
                            {addButton}
                        </div>
                    )}
                </Droppable>
            </div>
        </DragDropContext>
    );
