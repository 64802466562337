import React from 'react';

export const CheckBox = ({checked}) => (
    <svg
        className={`checkBox${checked ? ' checked' : ''}`}
        viewBox="0 0 132.29166 132.29167"
        height="35" width="35"
    >
            <path
                d="m 10.924959,17.515864 7.384214,-8.0947911 2.070114,7.0942151 0.0095,90.248492 89.714263,0.33483 0.54025,-90.469474 -90.263871,-0.113848 -2.070115,-7.0942151 96.390046,-0.033179 8.52678,11.0587641 -0.17363,92.233642 -9.78754,10.89971 -92.027722,-0.27385 -10.358292,-9.04231 z"
            />
    </svg>
);