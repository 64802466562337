import React, {useEffect, useState} from 'react';
import './App.css';
import {List} from "./List/List";
import {dummyList} from "./utils";
import {Settings} from "./Settings/Settings";
import {EditableList} from "./List/EditableList/EditableList";
import {Nav} from "./general/Nav/Nav";

const getLists = () => {
    let lists = localStorage.getItem('lists');
    return lists ?
        JSON.parse(lists) :
        dummyList();
}

let refs = {}, sortTimer

const App = () => {
    const [showSettings, setShowSettings] = useState(false);
    const [lists, setLists] = useState(getLists())
    const [editingList, setEditingList] = useState(null)
    const [colorMode, setColorMode] = useState(localStorage.getItem('colorMode') || 'dark')
    const [sortLists, setSortLists] = useState(localStorage.getItem('sortLists') || false)
    const [refsLoaded, setRefsLoaded] = useState(false);

    useEffect(() => {
        setRefsLoaded(true);
    }, [])

    const saveColorMode = cm => {
        localStorage.setItem('colorMode', cm);
        setColorMode(cm);
    }

    const saveSortLists = sl => {
        localStorage.setItem('sortLists', sl);
        setSortLists(sl);
    }

    const saveLists = l => {
        localStorage.setItem('lists', JSON.stringify(l));
        setLists(l);
    }

    const updateList = (list, i, sorted) => {
        let newLists = [...lists];
        newLists[i] = list;
        localStorage.setItem('lists', JSON.stringify(newLists));
        setLists(newLists);

        // sortTimer
        if (sortLists && !sorted)
            doSortList(list, i)
    }

    const doSortList = (list, i) => {
        if (sortTimer)
            clearTimeout(sortTimer);
        sortTimer = setTimeout(() => {
            let newList = {...list};
            newList.items = newList.items.sort(
                (a, b) => {
                    return a.checked && !b.checked ? 1 :
                        b.checked && !a.checked ? -1 : 0;
                }
            )
            updateList(newList, i, true)
        }, 450);
    }


    return (
        <div className={`App ${colorMode}`}>
            {refsLoaded && (<Nav refs={refs}/>)}
            {editingList !== null && (
                <EditableList
                    list={lists[editingList]}
                    updateList={list => updateList(list, editingList)}
                    openSettings={() => {
                        setEditingList(null)
                        setShowSettings(true)
                    }}
                    close={() => setEditingList(null)}
                />
            )}
            {(showSettings || lists.length === 0) && (
                <Settings
                    close={() => setShowSettings(false)}
                    lists={lists}
                    saveLists={saveLists}
                    colorMode={colorMode}
                    setColorMode={saveColorMode}
                    sortLists={sortLists}
                    setSortLists={saveSortLists}
                />
            )}
            <div className="appContent">
                <div className="scrollyContent">
                    {
                        lists.map((list, i) => {
                            // const ref = React.createRef();

                            return (
                                    <List
                                        key={list.id}
                                        list={list}
                                        updateList={list => updateList(list, i)}
                                        openSettings={() => setShowSettings(true)}
                                        edit={() => {
                                            console.log('set thing ', i);
                                            setEditingList(i)
                                        }}
                                        // listRef={ref}
                                        refs={refs}
                                        i={i}
                                        sortLists={sortLists}
                                    />
                                )
                            }
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export default App;
