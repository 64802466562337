import React from 'react';
import './List.css';
import {ListItem} from "./ListItem";
import {AddItemButton} from "./AddItemButton";
import {ListHeader} from "./ListHeader";

export const List = ({list, updateList, openSettings, edit, refs, i, sortLists}) => (
    <div
        className="pane"
        ref={node => {
            refs[`pane${i}`] = {
                label: list.label,
                node: node
            }
        }}
    >
        <ListHeader
            openSettings={openSettings}
            list={list}
            edit={edit}
            editing={false}
        />
        {list.items?.length === 0 ? (
            <AddItemButton
                list={list}
                updateList={updateList}
                fullScreen
            />
        ) : (
            <div className="items">
                {list.items.map((item, i) => (
                    <ListItem
                        key={item.id}
                        item={item}
                        toggleChecked={() => {
                            let newList = {...list};
                            let items = newList.items = [...newList.items];
                            let item = items[i] = {...items[i]};
                            item.checked = !item.checked
                            updateList(newList);
                        }}
                    />
                ))}
            </div>
        )}
    </div>
    )
