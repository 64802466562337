import React, {useState} from 'react';
import './Nav.css';

export const Nav = ({refs}) => {
    const [expanded, setExpanded] = useState(false)

    const lists = Object.values(refs);

    const renderRef = (ref, i) => {
        return <p
            key={`${i}${ref.label}`}
            onClick={() => {
                // ref.node.scrollIntoView();
                ref.node.scrollIntoView({ behavior: 'smooth', block: 'center' });
                setExpanded(false)
            }}
        >
            {ref.label}
        </p>
    }

    return (
        <>
            <div
                className="navButton"
                onClick={() => setExpanded(!expanded)}
            >
                {expanded ? '<' : 'Nav'}
            </div>
            <div className={`nav${expanded ? ' nav-expanded' : ''}`}>
                <h1>Navigation</h1>
                {lists.map(renderRef)}
            </div>
        </>
    );
}