import React, {useState} from 'react';
import {CheckBox} from "../general/CheckBox";

export const ListItem = ({item, toggleChecked}) => {
    const [checked, setChecked] = useState(item.checked)

    return (
        <div
            className={`listItem checkable${checked ? ' checked' : ' unchecked'}`}
            onClick={() => {
                setChecked(!checked)
                setTimeout(toggleChecked, 1) // sluggish on phone without this for some reason
            }}
        >
            <CheckBox checked={checked}/>
            <p>
                {item.text}
            </p>
        </div>
    );
}